.side-bar-link {
  font-size: 20px;
  text-align: center;
  color: black;
  text-decoration: none;
  transition: 1s;
}

.side-bar-link:hover {
  font-size: 22px;
}

a {
  text-decoration: none;
}

.button {
  border: solid rgb(80, 80, 80) 2px;
  border-top: none;
  box-sizing: "content-box";
  background: rgb(200, 200, 200);
  min-height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.button-pressed {
  @extend .button;
  background: rgb(240, 240, 240);
}
