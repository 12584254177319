.logo-box {
  align-items: center;
  justify-content: center;
  display: flex;
  text-decoration: none;
}

.image-logo {
  height: 30px;
}

.test-logo {
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: -2px;
  padding: 0 5px;
  text-transform: uppercase;
  color: black;
  font-family: "Titillium Web", sans-serif;
}
