$accent: rgb(58, 154, 114);
$smooth-accent: rgb(161, 204, 180);

.tableWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
}

.button-box {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  gap: 15px;
  font-size: 20px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.editorbutton-box {
  @extend .button-box;
  padding: 10px 0;
  justify-content: center;
}

.button {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  min-height: 40px;
  padding: 0 20px;
  background: $smooth-accent;
  border-radius: 5px;
  transition: 0.5s;
}

.button-pressed {
  @extend .button;
  background: $accent;
}

.button:hover {
  transition: 1s;
  font-size: 22px;
  cursor: pointer;
}

.uploaderEditorBox {
  display: flex;
  flex-direction: column;
  position: fixed;
  background: $smooth-accent;
  margin: auto 0;
  padding: 10px 50px;
  width: 800px;
  z-index: 1;
  border: solid black 2px;
  border-radius: 20px;
}

.closeIconWrapper {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
}

.formWrapper {
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.formBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  font-size: 18px;
}

.submitButton {
  @extend .button;
  background: transparent;
  border: solid black 1px;
  width: 200px;
}

.picturePreview {
  display: flex;
  object-fit: contain;
  height: 200px;
}

.textEditorBox {
  @extend .uploaderEditorBox;
  z-index: 1;
  background: white;
  overflow-y: scroll;
  max-height: 80%;
}
