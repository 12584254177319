* {
  font-family: "Hind Madurai", sans-serif;
  text-decoration-skip-ink: none;
}

body {
  margin: 0;
  padding: 0;
  position: fixed;
  font-family: -apple-system, BlinkMacSystemFont, "Hind Madurai",
    "Titillium Web";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  background: white;
}

.formBlock {
  padding: 30px 20px;
  gap: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  color: grey;
}

.centralWrapper {
  min-height: 100vh;
  display: flex;
  top: 40%;
  align-items: center;
  justify-content: center;
}

::-webkit-scrollbar {
  display: none;
}
